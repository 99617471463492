exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-ai-and-automation-jsx": () => import("./../../../src/pages/ai-and-automation.jsx" /* webpackChunkName: "component---src-pages-ai-and-automation-jsx" */),
  "component---src-pages-ai-cx-assessment-jsx": () => import("./../../../src/pages/ai-cx-assessment.jsx" /* webpackChunkName: "component---src-pages-ai-cx-assessment-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-support-outsourcing-jsx": () => import("./../../../src/pages/customer-support-outsourcing.jsx" /* webpackChunkName: "component---src-pages-customer-support-outsourcing-jsx" */),
  "component---src-pages-cx-services-community-management-jsx": () => import("./../../../src/pages/cx-services/community-management.jsx" /* webpackChunkName: "component---src-pages-cx-services-community-management-jsx" */),
  "component---src-pages-cx-services-consulting-services-jsx": () => import("./../../../src/pages/cx-services/consulting-services.jsx" /* webpackChunkName: "component---src-pages-cx-services-consulting-services-jsx" */),
  "component---src-pages-cx-services-content-moderation-jsx": () => import("./../../../src/pages/cx-services/content-moderation.jsx" /* webpackChunkName: "component---src-pages-cx-services-content-moderation-jsx" */),
  "component---src-pages-cx-services-customer-support-jsx": () => import("./../../../src/pages/cx-services/customer-support.jsx" /* webpackChunkName: "component---src-pages-cx-services-customer-support-jsx" */),
  "component---src-pages-cx-services-index-js": () => import("./../../../src/pages/cx-services/index.js" /* webpackChunkName: "component---src-pages-cx-services-index-js" */),
  "component---src-pages-cx-services-social-media-jsx": () => import("./../../../src/pages/cx-services/social-media.jsx" /* webpackChunkName: "component---src-pages-cx-services-social-media-jsx" */),
  "component---src-pages-cx-services-technical-support-jsx": () => import("./../../../src/pages/cx-services/technical-support.jsx" /* webpackChunkName: "component---src-pages-cx-services-technical-support-jsx" */),
  "component---src-pages-cx-services-trust-and-safety-jsx": () => import("./../../../src/pages/cx-services/trust-and-safety.jsx" /* webpackChunkName: "component---src-pages-cx-services-trust-and-safety-jsx" */),
  "component---src-pages-extralife-js": () => import("./../../../src/pages/extralife.js" /* webpackChunkName: "component---src-pages-extralife-js" */),
  "component---src-pages-go-customer-support-6-js": () => import("./../../../src/pages/go/customer-support-6.js" /* webpackChunkName: "component---src-pages-go-customer-support-6-js" */),
  "component---src-pages-go-metaverse-js": () => import("./../../../src/pages/go/metaverse.js" /* webpackChunkName: "component---src-pages-go-metaverse-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-crypto-and-nfts-jsx": () => import("./../../../src/pages/industries/crypto-and-nfts.jsx" /* webpackChunkName: "component---src-pages-industries-crypto-and-nfts-jsx" */),
  "component---src-pages-industries-e-commerce-and-retail-jsx": () => import("./../../../src/pages/industries/e-commerce-and-retail.jsx" /* webpackChunkName: "component---src-pages-industries-e-commerce-and-retail-jsx" */),
  "component---src-pages-industries-gaming-jsx": () => import("./../../../src/pages/industries/gaming.jsx" /* webpackChunkName: "component---src-pages-industries-gaming-jsx" */),
  "component---src-pages-industries-gaming-outsourcing-player-support-jsx": () => import("./../../../src/pages/industries/gaming/outsourcing-player-support.jsx" /* webpackChunkName: "component---src-pages-industries-gaming-outsourcing-player-support-jsx" */),
  "component---src-pages-industries-metaverse-jsx": () => import("./../../../src/pages/industries/metaverse.jsx" /* webpackChunkName: "component---src-pages-industries-metaverse-jsx" */),
  "component---src-pages-industries-technology-jsx": () => import("./../../../src/pages/industries/technology.jsx" /* webpackChunkName: "component---src-pages-industries-technology-jsx" */),
  "component---src-pages-industries-travel-guide-to-brand-protection-js": () => import("./../../../src/pages/industries/travel/guide-to-brand-protection.js" /* webpackChunkName: "component---src-pages-industries-travel-guide-to-brand-protection-js" */),
  "component---src-pages-industries-travel-index-jsx": () => import("./../../../src/pages/industries/travel/index.jsx" /* webpackChunkName: "component---src-pages-industries-travel-index-jsx" */),
  "component---src-pages-mods-index-jsx": () => import("./../../../src/pages/mods/index.jsx" /* webpackChunkName: "component---src-pages-mods-index-jsx" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-press-center-jsx": () => import("./../../../src/pages/press-center.jsx" /* webpackChunkName: "component---src-pages-press-center-jsx" */),
  "component---src-pages-privacy-and-cookies-js": () => import("./../../../src/pages/privacy-and-cookies.js" /* webpackChunkName: "component---src-pages-privacy-and-cookies-js" */),
  "component---src-pages-remote-jsx": () => import("./../../../src/pages/remote.jsx" /* webpackChunkName: "component---src-pages-remote-jsx" */),
  "component---src-pages-resources-geneverse-case-study-js": () => import("./../../../src/pages/resources/geneverse-case-study.js" /* webpackChunkName: "component---src-pages-resources-geneverse-case-study-js" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-resources-spotify-case-study-js": () => import("./../../../src/pages/resources/spotify-case-study.js" /* webpackChunkName: "component---src-pages-resources-spotify-case-study-js" */),
  "component---src-pages-resources-vimeo-case-study-js": () => import("./../../../src/pages/resources/vimeo-case-study.js" /* webpackChunkName: "component---src-pages-resources-vimeo-case-study-js" */),
  "component---src-pages-resources-wounded-warrior-project-case-study-js": () => import("./../../../src/pages/resources/wounded-warrior-project-case-study.js" /* webpackChunkName: "component---src-pages-resources-wounded-warrior-project-case-study-js" */),
  "component---src-pages-security-index-js": () => import("./../../../src/pages/security/index.js" /* webpackChunkName: "component---src-pages-security-index-js" */),
  "component---src-pages-security-manifesto-js": () => import("./../../../src/pages/security/manifesto.js" /* webpackChunkName: "component---src-pages-security-manifesto-js" */),
  "component---src-pages-spend-less-deliver-more-jsx": () => import("./../../../src/pages/spend-less-deliver-more.jsx" /* webpackChunkName: "component---src-pages-spend-less-deliver-more-jsx" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-zendesk-js": () => import("./../../../src/pages/thank-you-zendesk.js" /* webpackChunkName: "component---src-pages-thank-you-zendesk-js" */),
  "component---src-pages-the-blog-10-keys-to-improving-csat-with-zendesk-jsx": () => import("./../../../src/pages/the-blog/10-keys-to-improving-csat-with-zendesk.jsx" /* webpackChunkName: "component---src-pages-the-blog-10-keys-to-improving-csat-with-zendesk-jsx" */),
  "component---src-pages-the-blog-a-moderation-trailblazer-retires-jsx": () => import("./../../../src/pages/the-blog/a-moderation-trailblazer-retires.jsx" /* webpackChunkName: "component---src-pages-the-blog-a-moderation-trailblazer-retires-jsx" */),
  "component---src-pages-the-blog-ai-and-cx-7-questions-to-ask-yourself-js": () => import("./../../../src/pages/the-blog/ai-and-cx-7-questions-to-ask-yourself.js" /* webpackChunkName: "component---src-pages-the-blog-ai-and-cx-7-questions-to-ask-yourself-js" */),
  "component---src-pages-the-blog-bestofus-2024-jsx": () => import("./../../../src/pages/the-blog/bestofus-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-bestofus-2024-jsx" */),
  "component---src-pages-the-blog-beyond-self-support-jsx": () => import("./../../../src/pages/the-blog/beyond-self-support.jsx" /* webpackChunkName: "component---src-pages-the-blog-beyond-self-support-jsx" */),
  "component---src-pages-the-blog-calculating-the-true-cost-of-fte-in-outsourced-cx-jsx": () => import("./../../../src/pages/the-blog/calculating-the-true-cost-of-fte-in-outsourced-cx.jsx" /* webpackChunkName: "component---src-pages-the-blog-calculating-the-true-cost-of-fte-in-outsourced-cx-jsx" */),
  "component---src-pages-the-blog-cost-of-customer-support-jsx": () => import("./../../../src/pages/the-blog/cost-of-customer-support.jsx" /* webpackChunkName: "component---src-pages-the-blog-cost-of-customer-support-jsx" */),
  "component---src-pages-the-blog-customer-support-models-and-tiers-jsx": () => import("./../../../src/pages/the-blog/customer-support-models-and-tiers.jsx" /* webpackChunkName: "component---src-pages-the-blog-customer-support-models-and-tiers-jsx" */),
  "component---src-pages-the-blog-customer-support-strategy-jsx": () => import("./../../../src/pages/the-blog/customer-support-strategy.jsx" /* webpackChunkName: "component---src-pages-the-blog-customer-support-strategy-jsx" */),
  "component---src-pages-the-blog-gaming-trends-2024-jsx": () => import("./../../../src/pages/the-blog/gaming-trends-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-gaming-trends-2024-jsx" */),
  "component---src-pages-the-blog-get-ready-for-extra-life-2024-jsx": () => import("./../../../src/pages/the-blog/get-ready-for-extra-life-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-get-ready-for-extra-life-2024-jsx" */),
  "component---src-pages-the-blog-globee-2024-jsx": () => import("./../../../src/pages/the-blog/globee-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-globee-2024-jsx" */),
  "component---src-pages-the-blog-how-to-automate-customer-support-jsx": () => import("./../../../src/pages/the-blog/how-to-automate-customer-support.jsx" /* webpackChunkName: "component---src-pages-the-blog-how-to-automate-customer-support-jsx" */),
  "component---src-pages-the-blog-how-to-continuously-improve-customer-support-jsx": () => import("./../../../src/pages/the-blog/how-to-continuously-improve-customer-support.jsx" /* webpackChunkName: "component---src-pages-the-blog-how-to-continuously-improve-customer-support-jsx" */),
  "component---src-pages-the-blog-index-js": () => import("./../../../src/pages/the-blog/index.js" /* webpackChunkName: "component---src-pages-the-blog-index-js" */),
  "component---src-pages-the-blog-key-performance-indicators-jsx": () => import("./../../../src/pages/the-blog/key-performance-indicators.jsx" /* webpackChunkName: "component---src-pages-the-blog-key-performance-indicators-jsx" */),
  "component---src-pages-the-blog-make-the-most-of-your-customer-data-with-zendesk-and-modsquad-jsx": () => import("./../../../src/pages/the-blog/make-the-most-of-your-customer-data-with-zendesk-and-modsquad.jsx" /* webpackChunkName: "component---src-pages-the-blog-make-the-most-of-your-customer-data-with-zendesk-and-modsquad-jsx" */),
  "component---src-pages-the-blog-meet-ai-your-new-co-worker-js": () => import("./../../../src/pages/the-blog/meet-ai-your-new-co-worker.js" /* webpackChunkName: "component---src-pages-the-blog-meet-ai-your-new-co-worker-js" */),
  "component---src-pages-the-blog-mod-of-the-month-april-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-april-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-april-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-august-2024-jsx": () => import("./../../../src/pages/the-blog/mod-of-the-month-august-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-august-2024-jsx" */),
  "component---src-pages-the-blog-mod-of-the-month-february-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-february-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-february-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-january-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-january-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-january-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-july-2024-jsx": () => import("./../../../src/pages/the-blog/mod-of-the-month-july-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-july-2024-jsx" */),
  "component---src-pages-the-blog-mod-of-the-month-june-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-june-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-june-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-march-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-march-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-march-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-may-2024-js": () => import("./../../../src/pages/the-blog/mod-of-the-month-may-2024.js" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-may-2024-js" */),
  "component---src-pages-the-blog-mod-of-the-month-november-2024-jsx": () => import("./../../../src/pages/the-blog/mod-of-the-month-november-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-november-2024-jsx" */),
  "component---src-pages-the-blog-mod-of-the-month-october-2024-jsx": () => import("./../../../src/pages/the-blog/mod-of-the-month-october-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-october-2024-jsx" */),
  "component---src-pages-the-blog-mod-of-the-month-september-2024-jsx": () => import("./../../../src/pages/the-blog/mod-of-the-month-september-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-mod-of-the-month-september-2024-jsx" */),
  "component---src-pages-the-blog-nobody-gets-fired-hiring-a-call-center-js": () => import("./../../../src/pages/the-blog/nobody-gets-fired-hiring-a-call-center.js" /* webpackChunkName: "component---src-pages-the-blog-nobody-gets-fired-hiring-a-call-center-js" */),
  "component---src-pages-the-blog-saas-customer-support-jsx": () => import("./../../../src/pages/the-blog/saas-customer-support.jsx" /* webpackChunkName: "component---src-pages-the-blog-saas-customer-support-jsx" */),
  "component---src-pages-the-blog-scaling-ecommerce-customer-support-with-gorgias-jsx": () => import("./../../../src/pages/the-blog/scaling-ecommerce-customer-support-with-gorgias.jsx" /* webpackChunkName: "component---src-pages-the-blog-scaling-ecommerce-customer-support-with-gorgias-jsx" */),
  "component---src-pages-the-blog-summer-seasonal-support-js": () => import("./../../../src/pages/the-blog/summer-seasonal-support.js" /* webpackChunkName: "component---src-pages-the-blog-summer-seasonal-support-js" */),
  "component---src-pages-the-blog-thank-you-for-supporting-extra-life-2024-jsx": () => import("./../../../src/pages/the-blog/thank-you-for-supporting-extra-life-2024.jsx" /* webpackChunkName: "component---src-pages-the-blog-thank-you-for-supporting-extra-life-2024-jsx" */),
  "component---src-pages-the-blog-the-best-call-center-location-for-2025-js": () => import("./../../../src/pages/the-blog/the-best-call-center-location-for-2025.js" /* webpackChunkName: "component---src-pages-the-blog-the-best-call-center-location-for-2025-js" */),
  "component---src-pages-the-blog-the-hidden-costs-of-traditional-outsourcing-js": () => import("./../../../src/pages/the-blog/the-hidden-costs-of-traditional-outsourcing.js" /* webpackChunkName: "component---src-pages-the-blog-the-hidden-costs-of-traditional-outsourcing-js" */),
  "component---src-pages-the-blog-top-five-digital-trends-2024-js": () => import("./../../../src/pages/the-blog/top-five-digital-trends-2024.js" /* webpackChunkName: "component---src-pages-the-blog-top-five-digital-trends-2024-js" */),
  "component---src-pages-the-blog-traditional-outsourcing-is-an-outdated-approach-js": () => import("./../../../src/pages/the-blog/traditional-outsourcing-is-an-outdated-approach.js" /* webpackChunkName: "component---src-pages-the-blog-traditional-outsourcing-is-an-outdated-approach-js" */),
  "component---src-pages-the-blog-why-share-of-voice-matters-in-social-media-jsx": () => import("./../../../src/pages/the-blog/why-share-of-voice-matters-in-social-media.jsx" /* webpackChunkName: "component---src-pages-the-blog-why-share-of-voice-matters-in-social-media-jsx" */),
  "component---src-pages-why-modsquad-jsx": () => import("./../../../src/pages/why-modsquad.jsx" /* webpackChunkName: "component---src-pages-why-modsquad-jsx" */)
}

