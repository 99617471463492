import React from "react"
import Link from "@/components/atoms/Link";
// Import Components
import CookieNotice from "@/components/molecules/CookieNotice"
// Images
import Vespa from "@/images/footer-vespa.png"
import roundel from "@/images/roundel.png"
import Logo from "@/images/msq-logo.svg"

// const SocialLink = props => (
//   <div>
//       <Link//           sx={{
//               variant: 'styles.a.mobilemenu',
//               fontSize: 0,
//               fontFamily: 'sans',
//               textTransform: 'uppercase',
//               fontweight: 100,
//           }}
//           href={props.href}
//           target="_blank"
//           rel="noreferrer"
//       >
//           {props.children}
//       </Link>
//   </div>
// )

// const SocialIcon = props => (
//   <div>
//     <img src={props.src}
//     alt={props.alt}
//     sx={{width: 30,
//          bg: 'white',
//          '&:hover': {
//            bg: 'contiYellow',
//          },
//     }}
//     />
//   </div>
// )

const FooterSimple = () => {
    return (
        <footer
            sx={{
                mx: "auto",
                backgroundColor: "#111",
                color: "#fff",
                padding: 4,
                // pt: 160,
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
                alignItems: ["top", "center"],
                height: [240, 300],
            }}
        >
            {/* <FooterNav /> */}

            <Link to="/" context="footer">
                <img
                    src={Logo}
                    sx={{
                        width: 140,
                    }}
                    alt="ModSquad Logo"
                />
            </Link>

            <div
                sx={{
                    position: "absolute",
                    textAlign: "left",
                    bottom: [2, 32],
                    left: [2, 32],
                }}
            >
                <div
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <img src={roundel} alt="ModSquad Roundel" width="40" />
                    <p
                        sx={{
                            fontSize: 12,
                            fontFamily: "sans",
                            fontWeight: "100",
                            color: "white",
                        }}
                    >
                        <Link
                            to="/privacy-and-cookies"
                            context="footer-lower"
                        >
                            <Link                                variant="styles.a.thinWhite"
                                sx={{ textTransform: "uppercase" }}
                            >
                                Privacy and Cookies
                            </Link>
                        </Link>
                        <br />©{new Date().getFullYear()} ModSquad, Inc
                    </p>
                </div>
            </div>

            <div
                sx={{
                    display: ["none", "block"],
                    position: "absolute",
                    bottom: 16,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                855.818.MODS
            </div>

            <img
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: [140, 200],
                }}
                src={Vespa}
                alt="Scooter"
            />

            <CookieNotice />
        </footer>
    )
}

export default FooterSimple
